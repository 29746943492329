<template>
    <span class="annual-coupon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.08571 15C6.55175 15 6.98749 14.8206 7.31268 14.4947L14.4981 7.31021C14.8067 7.00223 14.9849 6.5885 14.9999 6.14564L15 2.73019C14.9991 1.77724 14.2234 1.00195 13.2708 1.00182L9.86844 1C9.41292 1.01457 8.99852 1.19314 8.68998 1.50319L1.50493 8.68769C1.17976 9.01245 1.0004 9.44789 1 9.91336C0.99987 10.379 1.17859 10.8147 1.5039 11.1402L4.85968 14.4954C5.18485 14.8208 5.62047 15 6.08571 15ZM11.483 6.13242C12.3734 6.13242 13.0983 5.40784 13.0984 4.51719C13.0979 3.62627 12.3733 2.90169 11.483 2.90169C10.5927 2.90182 9.86768 3.6264 9.86754 4.51692C9.86754 5.40771 10.5922 6.13229 11.483 6.13242Z"
                fill="white" />
        </svg>
    </span>
</template>

<script>
    export default {
        name: 'AnnualCouponIcon',
    };
</script>
