import VueRouter from 'vue-router';
import Domain from 'helpers/Domain';
import ForgotPassword from 'aa/vue/models/steps/ForgotPassword';
import GlobalResetPassword from 'aa/vue/models/steps/GlobalResetPassword';
import MVPDStep from 'aa/vue/models/steps/MVPDStep';
import { AA_BASE_PATH, ACCOUNT_BASE_PATH } from 'aa/vue/constants/aaRoutes';

import ResetPassword from 'aa/vue/models/steps/ResetPassword';
import ChangePassword from 'aa/vue/models/steps/ChangePassword';
import GlobalChangePassword from 'aa/vue/models/steps/GlobalChangePassword';
import SignIn from 'aa/vue/models/steps/SignIn';
import SignInIntl from 'aa/vue/models/steps/SignInIntl';
import ForgotPasswordIntl from 'aa/vue/models/steps/ForgotPasswordIntl';
import SignInCBSSports from 'aa/vue/models/steps/SignInCBSSports';
import Partner from 'aa/vue/models/steps/Partner';
import Step from 'models/steps/Step';
import SummaryStep from 'aa/vue/models/steps/Summary';

function getStepModelObject(domesticStep, intlStep) {
    return Domain.isDomestic() ? new domesticStep() : new intlStep();
}

function generateRoutes(config, regionBaseUrl) {
    let { store, steps, nonFlowRoutes } = config;

    nonFlowRoutes = nonFlowRoutes || [];

    let routes = [];

    if (typeof steps !== 'undefined') {
        store.dispatch('flow/setSteps', steps);
    }

    let signInStep = config.signInStep || getStepModelObject(SignIn, SignInIntl);

    signInStep.setProps({
        ...signInStep.props,
        hideForgotPassLink: config.hideForgotPassLink,
    });

    nonFlowRoutes = [
        signInStep,
        new SignInCBSSports(),
        getStepModelObject(ForgotPassword, ForgotPasswordIntl),
        new ResetPassword(),
        new ChangePassword(),
        new GlobalChangePassword(),
        new GlobalResetPassword(),
        new MVPDStep(),
        new Partner(),
        {
            path: `${ACCOUNT_BASE_PATH}resetpassword/`,
            alias: ['/global/resetpassword/'],
            redirect: {
                name: 'FORGOT_PASSWORD',
            },
        },
        {
            path: `${ACCOUNT_BASE_PATH}changepassword/`,
            alias: ['/global/changepassword/'],
            redirect: { name: 'FORGOT_PASSWORD' },
        },
        new SummaryStep(),
        ...nonFlowRoutes,
    ];

    if (typeof steps !== 'undefined') {
        for (let route of [...steps, ...nonFlowRoutes]) {
            if (route instanceof Step) {
                route.path = (route.requireSub ? '' : regionBaseUrl || '') + route.path;
                route.store = store;
                routes.push(route.getRoute());
            } else if (route && typeof route === 'object') {
                routes.push(route);
            }
        }
    }

    return routes;
}

/**
 * @param config
 * @returns {{x: number, y: number}|*}
 */
export default function (routerConfig = {}) {
    let { store, regionBaseUrl } = routerConfig;
    let json = JSON.parse(document.getElementById('app-config').innerHTML);

    regionBaseUrl = regionBaseUrl || '';

    store.dispatch('loadServerData', json);

    let basePath = AA_BASE_PATH;

    if (store.getters['flow/isReseller']) {
        basePath = store.getters['flow/baseURL'];
    }

    let originalPush = VueRouter.prototype.push;

    VueRouter.prototype.push = function push(location, onResolve, onReject) {
        if (onResolve || onReject) {
            return originalPush.call(this, location, onResolve, onReject);
        }
        return originalPush.call(this, location).catch((err) => {
            if (VueRouter.isNavigationFailure(err)) {
                return err;
            }
            return Promise.reject(err);
        });
    };

    return new VueRouter({
        mode: 'history',
        base: basePath,
        routes: generateRoutes(routerConfig, regionBaseUrl),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return {
                    x: 0,
                    y: 0,
                };
            }
        },
    });
}
