import Step from 'models/steps/Step';
import SharedPaymentComponent from 'aa/vue/components/payment/SharedPaymentComponent';
import {
    handleProcessedPayment,
    handleProcessedPaymentError,
    redirectToSummary,
} from 'aa/vue/components/payment/signupUtil';
import UserStatus from 'aa/vue/models/UserStatus';
import Plan from 'aa/vue/models/Plan';
import { getNextBillingDate } from 'aa/vue/stores/modules/MultiSubPlanStore';

export const HAS_TRIAL_TAG = 'HAS_TRIAL';
export const NO_TRIAL_TAG = 'NO_TRIAL';
const planDoesHaveTrialTag = (plan) =>
    (plan.productTags || []).includes(NO_TRIAL_TAG) ||
    (plan.productTags || []).includes(HAS_TRIAL_TAG);

const ERROR_TRACKING_PAGE_TYPE = 'winback_directLink_error';
const GENERIC_ERROR = 'GenericError';
const GENERIC_ERROR_HEADING = 'oops_something_went_wrong';
const GENERIC_ERROR_SUBHEADING = 'direct_link_general_error';
export const getGenericErrorParams = ({ subHeading = [], primaryButton = {} } = {}) => ({
    name: GENERIC_ERROR,
    params: {
        heading: GENERIC_ERROR_HEADING,
        subHeading: [GENERIC_ERROR_SUBHEADING, ...subHeading],
        errorTrackingPageType: ERROR_TRACKING_PAGE_TYPE,
        primaryButton: primaryButton || {},
    },
});

export default class DirectLinkFunnel extends Step {
    path = '/account/direct/:directLinkFlow';
    name = 'DIRECTLINK';
    requireAuth = true;
    requireNonAuth = false;
    countStep = true;
    props = {
        isEditPayment: false,
        waitingOverlayEnabled: true,
        handleProcessedPayment,
        handleProcessedPaymentError,
        isFromDirect: true,
    };

    constructor() {
        super();
        this.component = SharedPaymentComponent;
    }

    async beforeEnter(to, from, next) {
        const primaryButton = {};
        let serverData;
        const serverDataElem = document.getElementById('app-config');
        try {
            serverData = JSON.parse(serverDataElem.innerHTML);
        } catch (e) {
            serverData = {};
        }

        if (serverData?.user.isExSubscriber || serverData?.user.isRegistered) {
            primaryButton.link = '/';
        }

        if (to.query && to.query.redirect_error) {
            next(getGenericErrorParams({ primaryButton }));
            return;
        }
        if (to.params.directLinkFlow !== 'su' && to.params.directLinkFlow !== 'si') {
            next(getGenericErrorParams());
            return;
        }

        if (!to.query || !to.query.tier || !to.query.cadence) {
            next(
                getGenericErrorParams({ subHeading: ['dl_error_plan_unavailable'], primaryButton }),
            );
            return;
        }

        // Tier can be a string (INTL) or a number (INTL/US)
        const isTierNumeric = !isNaN(parseInt(to.query.tier));
        // Obfuscate the free trial for direct link in the query params. When "url" is not "d", then free trial is enabled.
        const isTrial = to.query?.url !== 'd' && !serverData?.user?.isExSubscriber;

        const plans = serverData?.plans || [];
        const plan = plans.find((plan) => {
            const planTypeMatch = plan.planType === to.query.cadence;
            if (!planTypeMatch) {
                return false;
            }
            let planTrialMatch = (plan.productTags || []).includes(
                isTrial ? HAS_TRIAL_TAG : NO_TRIAL_TAG,
            );
            // US doesn't have a trial tag, so we need to check the trial flag
            if (!planDoesHaveTrialTag(plan)) {
                planTrialMatch = plan.trial === isTrial;
            }
            if (!planTrialMatch) {
                return false;
            }
            if (isTierNumeric) {
                return plan.tier === parseInt(to.query.tier);
            }
            return plan.planTier === to.query.tier;
        });

        if (!plan) {
            next(
                getGenericErrorParams({ subHeading: ['dl_error_plan_unavailable'], primaryButton }),
            );
            return;
        }

        const userStatus = this._store.getters['user/getUserPackageStatus'];

        if (userStatus === UserStatus.STATUS_CANCEL_SCHEDULED) {
            next(
                getGenericErrorParams({
                    subHeading: ['dl_error_active_cancel_state'],
                    primaryButton,
                }),
            );
            return;
        }

        this._store.dispatch('plan/setPlanTier', plan.planTier);
        this._store.dispatch('plan/setPlanType', plan.planType);
        this._store.dispatch('plan/updateSelectedPlanWithTypeandTier', plan);

        if (serverData?.directLinkAdditionalStoreUpdateEnabled) {
            this._store.dispatch('plan/updateSelectedPlan', plan);
        }

        const storePlans = this._store.getters['plan/getPlans'];

        const relevantPlans = storePlans
            .filter((p) => {
                if (!planDoesHaveTrialTag(p)) {
                    return p.trial === isTrial;
                }
                return (p.productTags || []).includes(isTrial ? HAS_TRIAL_TAG : NO_TRIAL_TAG);
            })
            .map((p) => {
                const nextBillingDate = getNextBillingDate(p);
                const planObj = {
                    ...p,
                    nextBillingDate,
                };
                if (!planDoesHaveTrialTag(p)) {
                    return new Plan(planObj);
                }
                return planObj;
            });

        // Set the plans in the store based on the trial status to avoid breaking the flow
        this._store.dispatch('plan/setPlans', relevantPlans);
        this._store.commit('flow/setCurrentStep', 2);

        this.getPromo(from);

        const isExSubscriber = this._store.getters['user/isExSubscriber'];
        if (isExSubscriber && this._store.state.serverData.paymentExsubBillingSummary) {
            const summaryRedirect = await redirectToSummary(this._store);
            next(summaryRedirect);
        }

        next();
    }
}
