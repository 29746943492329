import {
    initApp,
    getAppConfig,
    getSteps,
    featureSkipExplainerStepsEnabled,
    featureAgeGateEnabled,
    directLinkEnabled,
    isCatanFlow,
} from 'aa/vue/App';
import Feature from 'aa/helpers/featureConstants';
import MonthlyAnnualPicker from 'aa/vue/models/steps/MonthlyAnnualPicker';
import Plan from 'aa/vue/models/steps/Plan';
import CatanBundlePlanStep from 'aa/vue/models/steps/CatanBundlePlanStep';
import SignUp from 'aa/vue/models/steps/SignUp';
import Payment from 'aa/vue/models/steps/Payment';
import Summary from 'aa/vue/models/steps/Summary';
import PlanExplainer from 'aa/vue/models/steps/PlanExplainer';
import SignUpExplainer from 'aa/vue/models/steps/SignUpExplainer';
import ShowsPickerStep from 'aa/vue/models/steps/ShowPicker';
import CompilingPageStep from 'aa/vue/models/steps/CompilingPageStep';
import SuperFunnel from 'aa/vue/models/steps/SuperFunnel';
import DirectLinkFunnel from 'aa/vue/models/steps/DirectLinkFunnel';
import GenericError from 'aa/vue/models/routes/GenericError';
import 'helpers/tracking.js';
import 'aa/components/FooterAllAccess';
import 'aa/components/accessibilityAllAccess.js';
import PaymentExplainer from 'aa/vue/models/steps/PaymentExplainer'; // ~/src/web-allaccess/src/js/vue/models/steps/PaymentExplainer.js
import Tier from 'aa/vue/models/steps/Tier';
import Cadence from 'aa/vue/models/steps/Cadence';
import SignInShowtimeMigrated from 'aa/vue/models/steps/SignInShowtimeMigrated';
import Aarp from 'aa/vue/models/steps/Aarp';
import AgeGate from 'aa/vue/models/steps/AgeGate';
import PartnerBundleStep from 'aa/vue/models/steps/PartnerBundleStep';
import SignInNoggin from 'aa/vue/models/steps/SignInNoggin';

function getPlanSteps() {
    if (isCatanFlow()) {
        return { CatanBundlePlanStep };
    } else {
        return { Plan, PartnerBundleStep };
    }
}

// NOTE:: after adding new step, update options.jsonc with the same step and do pnpm run prebuild
let stepModules = {
    PlanExplainer,
    ...getPlanSteps(),
    SignUpExplainer,
    SignUp,
    MonthlyAnnualPicker,
    Tier,
    Cadence,
    Payment,
    Summary,
    PaymentExplainer,
    ShowsPickerStep,
    CompilingPageStep,
};
const appConfig = getAppConfig();

if (featureSkipExplainerStepsEnabled()) {
    stepModules = {
        ...getPlanSteps(),
        SignUp,
        MonthlyAnnualPicker,
        Tier,
        Cadence,
        Payment,
        Summary,
        ShowsPickerStep,
        CompilingPageStep,
    };
}

const steps = getSteps(stepModules, appConfig['accountRegistrationSteps']);

let nonFlowRoutes = [
    new SuperFunnel(),
    new SignInShowtimeMigrated(),
    new Aarp(),
    new SignInNoggin(),
    new GenericError(),
];
if (featureAgeGateEnabled()) {
    nonFlowRoutes.push(new AgeGate());
}
if (directLinkEnabled()) {
    nonFlowRoutes.push(new DirectLinkFunnel());
}
initApp({
    appConfig,
    steps,
    nonFlowRoutes,
});
